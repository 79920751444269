import { createI18n } from "vue-i18n";
import ee from "./locales/ee.json";
import ru from "./locales/ru.json";

const i18n = createI18n({
  legacy: false,
  locale: "ee",
  fallbackLocale: "ru",
  messages: {
    ee,
    ru,
  },
});

export default i18n;
