<template>
  <div class="language-container">
    <v-select
      v-model="selectedLanguage"
      :items="languages"
      item-value="value"
      item-title="title"
      @change="changeLanguage"
      class="language-selector"
      density="compact"
    ></v-select>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/store/userStore";
import { ref, onMounted, watch } from "vue";
import type { Language } from "@/store/userStore";
const { locale } = useI18n();
const userStore = useUserStore();
const selectedLanguage = ref<Language>(userStore.language as Language);
const languages = [
  { value: "ee", title: "Ee" },
  { value: "ru", title: "Ru" },
];

onMounted(() => {
  locale.value = selectedLanguage.value;
});

// Watch for changes to selectedLanguage
watch(selectedLanguage, (newLanguage) => {
  console.log("Watcher Triggered: Language to change is", newLanguage);
  locale.value = newLanguage;
  userStore.setLanguage(newLanguage);
});

const changeLanguage = (value: Language) => {
  // console.log("Changing Language to:", value);
  selectedLanguage.value = value;
};
</script>

<style scoped>
.language-container {
  display: flex;
  align-items: center;
}

.language-selector {
  width: 82px;
  height: 40px;
}
</style>
