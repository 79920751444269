import { defineStore } from "pinia";

export type Language = "ee" | "ru"; //app display language

interface userState {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  enabled: boolean | null;
  billingDate: string | null;
  lastLogin: string | null;
  lastPayment: string | null;
  role: string | null;
  tier: string | null;
  language: Language;
  dailyBaseline: number;
  currentTotalCompleted: number;
  currentProgress: number;
  todayDate: string;
}

export const useUserStore = defineStore({
  id: "user",
  state: (): userState => ({
    id: JSON.parse(localStorage.getItem("id") || "null"),
    firstName: JSON.parse(localStorage.getItem("firstName") || "null"),
    lastName: JSON.parse(localStorage.getItem("lastName") || "null"),
    email: JSON.parse(localStorage.getItem("email") || "null"),
    enabled: JSON.parse(localStorage.getItem("enabled") || "null"),
    billingDate: JSON.parse(localStorage.getItem("billingDate") || "null"),
    lastLogin: JSON.parse(localStorage.getItem("lastLogin") || "null"),
    lastPayment: JSON.parse(localStorage.getItem("lastPayment") || "null"),
    role: JSON.parse(localStorage.getItem("role") || "null"),
    tier: JSON.parse(localStorage.getItem("tier") || "null"),
    language: (localStorage.getItem("language") as Language) || "ru",
    dailyBaseline: parseInt(localStorage.getItem("dailyBaseline") || "0", 10),
    currentTotalCompleted: 0,
    currentProgress: 0,
    todayDate:
      localStorage.getItem("todayDate") ||
      new Date().toISOString().slice(0, 10),
  }),
  actions: {
    saveProfile(
      id: string | null,
      firstName: string | null,
      lastName: string | null,
      email: string | null,
      enabled: boolean | null,
      billingDate: string | null,
      lastLogin: string | null,
      lastPayment: string | null,
      role: string | null,
      tier: string | null,
    ) {
      this.id = id;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.enabled = enabled;
      this.billingDate = billingDate;
      this.lastLogin = lastLogin;
      this.lastPayment = lastPayment;
      this.role = role;
      this.tier = tier;

      localStorage.setItem("id", JSON.stringify(id));
      localStorage.setItem("firstName", JSON.stringify(firstName));
      localStorage.setItem("lastName", JSON.stringify(lastName));
      localStorage.setItem("email", JSON.stringify(email));
      localStorage.setItem("enabled", JSON.stringify(enabled));
      localStorage.setItem("billingDate", JSON.stringify(billingDate));
      localStorage.setItem("lastLogin", JSON.stringify(lastLogin));
      localStorage.setItem("lastPayment", JSON.stringify(lastPayment));
      localStorage.setItem("role", JSON.stringify(role));
      localStorage.setItem("tier", JSON.stringify(tier));
    },

    setLanguage(lang: Language) {
      this.language = lang;
      localStorage.setItem("language", lang);
    },

    setDailyBaseline(startOfDayBaseline: number) {
      this.dailyBaseline = startOfDayBaseline;
      this.currentTotalCompleted = startOfDayBaseline; // Initialize as baseline
      this.currentProgress = 0; // Initialize progress to zero for a new day
      this.todayDate = new Date().toISOString().slice(0, 10);
      localStorage.setItem("dailyBaseline", startOfDayBaseline.toString());
      localStorage.setItem("todayDate", this.todayDate);
    },

    checkForNewDay() {
      const today = new Date().toISOString().slice(0, 10);
      if (!localStorage.getItem("dailyBaseline")) {
        this.setDailyBaseline(0);
        return true;
      }

      if (this.todayDate !== today) {
        this.todayDate = today;
        localStorage.setItem("todayDate", today);
        return true; //Indicate a new day has been detected
      }
      return false; // No new day, continue with existing data
    },

    updateProgress(currentCompletedCount: number) {
      this.currentTotalCompleted = currentCompletedCount;
      this.currentProgress = this.currentTotalCompleted - this.dailyBaseline;
    },
  },
});
