<template>
  <v-container class="my-container">
    <DailyMilestoneBar style="padding-bottom: 15px" />
    <v-sheet class="dialog-sheet">
      <v-dialog v-model="displayPopup" max-width="700px">
        <v-card variant="elevated">
          <p
            style="padding: 5px"
            v-html="$t('courseView.learnedWordExplanation')"
          ></p>
        </v-card>
      </v-dialog>
    </v-sheet>

    <v-row justify="center">
      <v-card variant="elevated" width="544px">
        <v-row align="center">
          <v-col cols="4">
            <v-card-title class="responsive-title"
              >{{ courseName }}
            </v-card-title>
          </v-col>

          <v-col cols="8">
            <v-card-subtitle :style="{ whiteSpace: 'normal' }">{{
              userTier === "FREE"
                ? $t("courseView.subscribe")
                : courseDescription
            }}</v-card-subtitle>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-btn
            v-if="!userEnrollment"
            variant="outlined"
            class="mb-4"
            @click="enrollToCourse"
            >{{ $t("courseView.startLearningButton") }}</v-btn
          >
        </v-row>

        <div v-if="isLoading">{{ $t("courseView.loading") }}</div>
        <TopicItem
          v-for="topic in topics"
          :key="topic.id"
          :topic="topic"
          :userTier="userTier"
        />
      </v-card>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import TopicItem from "@/components/TopicItem.vue";
import { useCourseStore } from "@/store/courseStore";
import axios from "axios";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import DailyMilestoneBar from "@/components/DailyMilestoneBar.vue";

const topics = ref([]);
const isLoading = ref(true);
const userTier = ref("");
const router = useRouter();
const route = useRoute(); // Import 'useRoute' from 'vue-router'
const displayPopup = ref(false);
let timeoutId = null;

const courseStore = useCourseStore();
const courseId = courseStore.currentCourse?.id;
const courseName = courseStore.currentCourse?.name;
const courseDescription = courseStore.currentCourse?.description;
const userEnrollment = courseStore.currentCourse?.userEnrollment;
const courseType = computed(() => courseStore.currentCourse?.type);

const getTopics = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`api/course/${courseId}/topics`);
    topics.value = response.data;
    const profileResponse = await axios.get("api/users/profile");
    userTier.value = profileResponse.data.tier;
    isLoading.value = false;
    if (route.query.showPopup) {
      displayPopup.value = true;
      timeoutId = setTimeout(() => {
        displayPopup.value = false;
      }, 3500);
    }
  } catch (error) {
    isLoading.value = false;
    console.error("Failed to fetch topics", error.message);
  }
};

const enrollToCourse = async () => {
  try {
    await axios.post(`api/course/${courseId}/enroll`);
    if (courseType.value === "SUBJECT") {
      await router.push("/exercises");
    } else {
      await router.push("/courses");
    }
  } catch (error) {
    console.error("Failed to enroll", error.message);
  }
};

onUnmounted(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});

onMounted(getTopics);
</script>

<style scoped>
.my-container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}

.responsive-title {
  //white-space: normal;
  //overflow-wrap: break-word;
  font-size: 1.1em;
  padding: 4px 0;
}
</style>
